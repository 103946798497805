.trip-match-modal {
  .trip-match-modal__flight-info {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .flight-number {
      position: absolute;
      top: -8px;
      left: -36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 26px;
      width: 26px;
      height: 26px;
      background: #efefef;
      color: var(--bs-body-color);
      font-size: 16px;
      font-weight: bold;
    }
  }
}
