@import "../../inputs-mixin";

$select-height-in-form-control: 38px;
$select-height-in-small-form-control: 31px;

@mixin overrideReactSelectHeight($height) {
  min-width: 140px;
  height: $height;

  .jw-select__control, .jw-select__indicators {
    min-height: $height - 2px; // -2 accounts for border of form-field
  }

  .jw-select__control, .jw-select__value-container, .jw-select__indicators {
    height: $height - 2px; // -2 accounts for border of form-field
  }
}

@mixin jw-select-color($color: map-get($colors, typography), $arrow-color: map-get($colors, primary)) {

  .jw-select__indicator {
    color: $arrow-color;
  }
  .jw-select__placeholder {
    color: $color;
  }
}

.jw-select {
  .jw-select__control {
    @include inputs();
    @include jw-select-color();
  }

  .jw-select__placeholder {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .jw-select__value-container {
    padding-right: map-get($spacing, xs)
  }

  .jw-select__indicator {
    padding: 0 map-get($spacing, sm) 0 0;
  }

  .jw-select__indicator-separator {
    display: none;
  }

  &.form-control {
    padding: 0;
    @include overrideReactSelectHeight($select-height-in-form-control);
  }

  &.form-control-sm {
    padding: 0;
    @include overrideReactSelectHeight($select-height-in-small-form-control);
  }
}

.jw-dummy-select {
  visibility: hidden;
  position: absolute;

  &:invalid {
    & + .jw-select {
      .was-validated & {
        .jw-select__control {
          @include inputs-invalid();

          .jw-select__placeholder {
            color: map-get($colors, red);
          }
        }

        border-color: map-get($colors, neutral-red);
      }
    }
  }
}
