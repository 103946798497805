@import "../../scss/variables";
@import "../../scss/typography-mixins";

.jw-colored-badge {
  padding: map-get($spacing, sm) map-get($spacing, lg);
  display: inline-block;
  border-radius: 37px; // should be height of badge
  text-transform: capitalize;
  @include typography(badge);
}

@each $colorName, $color in $badge-colors {
  .jw-colored-badge--#{$colorName} {
    background: $color;
  }
}
