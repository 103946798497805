$pill-border-radius: 56px;
$pill-padding-h: map-get($spacing, xl);

.jw-list-pill {
  margin-bottom: map-get($spacing, defaultComponentMargin);

  .jw-list-pill__item {
    background: map-get($colors, neutral-light);
    padding-left: $pill-padding-h / 2;
    padding-right: $pill-padding-h / 2;
    border: none;
    font-size: 14px;

    &:first-child {
      padding-left: $pill-padding-h;
      border-bottom-left-radius: $pill-border-radius;
      border-top-left-radius: $pill-border-radius;
    }

    &:last-child {
      padding-right: $pill-padding-h;
      border-bottom-right-radius: $pill-border-radius;
      border-top-right-radius: $pill-border-radius;
    }
  }

}
