@import "../../scss/variables";

.jw-button-row {
  display: flex;
  width: 100%;
  button, .btn, .btn-link {
    &:not(:first-child) {
      margin-left: map-get($spacing, lg);
    }
  }

  &.jw-button-row--left {justify-content: start;}
  &.jw-button-row--center {justify-content: center;}
  &.jw-button-row--right {justify-content: end;}
}
