@import "../../scss/variables";

$alerts-minimum-padding: map-get($spacing, lg);
$alerts-container-top: map-get($spacing, lg);
$alerts-max-width: 580px;

// alerts container is used to position the alerts on screen
.alerts-container {
  position: fixed;
  top: $alerts-container-top;
  left: 0;
  width: 100%;
  padding: $alerts-minimum-padding;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column-reverse;
  align-items: center;
  pointer-events: none;
  z-index: 9999;
}

// Alert animation
@mixin alert-out {
  opacity: 0;
  transform: scale(0.95,0.95);
  transition: opacity 500ms ease, transform 500ms ease;
}

@mixin alert-in {
  opacity: 1;
  transform: scale(1,1);
  transition: opacity 500ms ease, transform 500ms ease;
}

.alert-message {
  max-width: $alerts-max-width;
  width: 100%;

  &#{&}-enter {
    @include alert-out;
  }
  &#{&}-enter-active {
    @include alert-in;
  }
  &#{&}-exit {
    @include alert-in;
  }

  &#{&}-exit-done {
    @include alert-out;
  }

  &#{&}-exit-active {
    @include alert-out;
  }
}

