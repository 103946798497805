@import "variables";
$spacers: (
  0: 0,
  1: map-get($spacing, xs),
  2: map-get($spacing, sm),
  3: map-get($spacing, md),
  4: map-get($spacing, lg),
  5: map-get($spacing, xl),
  auto: auto,
);

@for $i from 0 through 5 {
  .ml-#{$i} {margin-left: map-get($spacers, $i) !important;}
  .mr-#{$i} {margin-right: map-get($spacers, $i);}
  .mb-#{$i} {margin-bottom: map-get($spacers, $i);}
  .mt-#{$i} {margin-top: map-get($spacers, $i);}
  .pl-#{$i} {padding-left: map-get($spacers, $i);}
  .pr-#{$i} {padding-right: map-get($spacers, $i);}
  .pb-#{$i} {padding-bottom: map-get($spacers, $i);}
  .pt-#{$i} {padding-left: map-get($spacers, $i);}
}

$danger: map-get($colors, red);
$text-muted: map-get($colors, typography-neutral);

// Forms
.form-group {
  padding-bottom: map-get($spacing, lg);
}

.jw-condensed-grid {
  padding: 0;
}

// Dropdown
.dropdown-toggle--toggle-less {
  &#{&} { // double specificity of css
    &:after {
      display: none;
    }
  }
}

.list-group {
  &.list-group--no-lines {
    .list-group-item {
      border: none;
    }

    &.list-group-horizontal {
      .list-group-item {
        padding: map-get($spacing, sm) map-get($spacing, md);
      }
     .list-group-item:first-child {
       padding-left: 0;
     }
    }
  }
}

body hr {
  background-color: map-get($colors, neutral-light);
  opacity: 1;
}
