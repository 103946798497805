@import "../../scss/variables";

.jw-page-layout {
  position: relative;
  min-height: 90vh;

  &.jw-page-layout--form {
    .jw-page-layout__container {
      max-width: 640px;
    }
  }

  .jw-page-layout__hero {
    position: absolute;
    top: $nav-height;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    object-position: top;
    object-fit: cover;
    z-index: -1;
    max-height: 500px;
  }

  .jw-page-layout__card-slot {
    margin-top: 10vw;
  }

  .jw-page-layout__bg-logo {
    position: absolute;
    bottom: -80px;
    right: 0;
    max-width: 25vw;
    z-index: -1;
  }
}


