@import "typography-mixins";

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-sm {
  @include typography(sm-paragraph);
}
.text-fine-print {
  @include typography(xs-paragraph)
}

.pl-0 {padding-left: 0 !important;}
.pt-0 {padding-top: 0 !important;}
.pr-0 {padding-right: 0 !important;}
.pb-0 {padding-bottom: 0 !important;}

.ml-0 {margin-left: 0 !important;}
.mt-0 {margin-top: 0 !important;}
.mr-0 {margin-right: 0 !important;}
.mb-0 {margin-bottom: 0 !important;}


@for $i from 8 through 64 {
  .fs-#{$i} {
    font-size: #{$i}px;
  }
}

@mixin all-headers {
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .h1-emphasized, .h2-emphasized, .h3-emphasized, .h4-emphasized, .h5-emphasized, .h6-emphasized,  {
    @content;
  }
}

