.full-screen-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .full-screen-loader__spinner {
    width: 80px;
    height: 80px;
  }
}
