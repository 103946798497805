@import "../../../scss/variables";
@import "../inputs-mixin";

$form-field-icon-width: 24px;
$form-field-icon-height: 20px;
$form-field-icon-padding-left: map-get($spacing, xs);
$form-field-icon-padding-right: map-get($spacing, sm);
$form-field-border-radius: 8px;

@mixin field-input-icon($url) {
  .jw-form-group__input-container {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: $form-field-icon-padding-left;
      top: 0;
      bottom: 0;
      margin: auto;
      width: $form-field-icon-width;
      height: $form-field-icon-height;
      background-image: url($url);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.jw-form-group {
  margin-bottom: map-get($spacing, md);

  .form-control, .jw-select__control {
    border-color: map-get($colors, neutral-light);
    border-radius: $form-field-border-radius;
  }

  .form-control::-webkit-input-placeholder {
    color: #bec6cc;
  }
  .form-control:-moz-placeholder {
    color: #bec6cc;
  }
  .form-control::-moz-placeholder {
    color: #bec6cc;
  }
  .form-control::placeholder {
    color: #bec6cc;
  }
  .form-control:-ms-input-placeholder {
    color: #bec6cc;
  }

  &.jw-form-group--compressed {
    margin-bottom: map-get($spacing, sm);

    .form-label {
      margin-bottom: map-get($spacing, sm);
    }
  }

  &.jw-form-group--has-icon {

    input, .jw-select__value-container {
      padding-left: $form-field-icon-width + $form-field-icon-padding-left + $form-field-icon-padding-right;
    }


    &.jw-form-group--takeoff {
      @include field-input-icon("../../../scss/icons/jet-takeoff.svg");
    }

    &.jw-form-group--landing {
      @include field-input-icon("../../../scss/icons/jet-landing.svg");
    }

    &.jw-form-group--search {
      @include field-input-icon("../../../scss/icons/search.svg");
    }

    &.jw-form-group--calendar {
      @include field-input-icon("../../../scss/icons/calendar.svg");
    }

    &.jw-form-group--clock {
      @include field-input-icon("../../../scss/icons/clock.svg");
    }

  }



  input {
    @include inputs();
  }

  .jw-form-feedback--tooltip {
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms ease-in-out;
    &.jw-form-feedback--focused {
      opacity: 1;
    }
  }

}
