.trip-requested-modal {

}

.trip-requested-modal_body {
  padding-left: map-get($spacing, xl);
  padding-right: map-get($spacing, xl);
  padding-bottom: map-get($spacing, sm);

  .trip-requested-modal_body__title {
    display: flex;
    justify-content: center;
    align-items: center;
    @include typography-emphasized-header();
    margin-bottom: map-get($spacing, sm);

    .trip-requested-modal_body__title__arrow {
      height: 24px;
      margin: map-get($spacing, sm) map-get($spacing, lg);
    }
  }

}
