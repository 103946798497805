.modal-loader {
  position: relative;

  .modal-loader__spinner-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(33,33,33,0.3);
    opacity: 0;
    transition: opacity ease-in-out 240ms, background-color ease-in-out 240ms;
    pointer-events: none;
  }

  .modal-loader__spinner {
    width: 80px;
    height: 80px;
  }

  &.modal-loader--loading {
    .modal-loader__spinner-container {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
