@import "../../../scss/variables";

$jw-form-field-group-labels-margin: 24px + map-get($spacing, md);
$jw-form-field-group-input-size: 178px;

.jw-form-field-group {
  position: relative;
  background: map-get($colors, neutral-light);
  border-radius: 46px;
  width: auto;
  margin-bottom: map-get($spacing, defaultComponentMargin);

  &.jw-form-field-group--has-labels {
    margin-top: $jw-form-field-group-labels-margin;

    .form-label {
      position: absolute;
      top: -1 * $jw-form-field-group-labels-margin;
      left: 0;
      right: 0;
    }
  }

  .jw-form-group {
    margin: map-get($spacing, xs) map-get($spacing, lg);
    min-width: $jw-form-field-group-input-size;
    max-width: $jw-form-field-group-input-size;

    &:not(:first-child) {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: map-get($spacing, sm) * -1;
        margin: map-get($spacing, xs) 0;
        width: 1px;
        border-left: 1px solid map-get($colors, neutral-gray);
        z-index: 1;
      }
    }
  }

  /* Handle case where inputs would wrap */
  &.jw-form-field-group--break-up {
    border-radius: 20px;

    &.jw-form-field-group--has-labels {
      margin-top: 0;

      .form-label {
        position: unset;
      }
    }

    .jw-form-group {
      margin: map-get($spacing, md) map-get($spacing, lg) !important;
      max-width: 100%;
      width: 100%;

      &:not(:first-child) {
        &:before {
          display: none;
        }
      }
    }
  }
}
