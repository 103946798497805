@import "../../scss/variables";

@mixin inputs($input-type: input) {
  background: map-get($colors, neutral-light);
  border: none;
  color: map-get($colors, dark);

  &:invalid {
    .was-validated & {
      @include inputs-invalid();
    }
  }
}

@mixin inputs-invalid() {
  background: map-get($colors, neutral-red);
  color: map-get($colors, red)
}
