@import "../../scss/variables";
@import "../../scss/typography-mixins";

.jw-navbar {
  background-color: map-get($colors, neutral-light);

  .nav-link {
    padding: 0.5rem 0.75rem !important;
    @include typography(nav-link);

    &.active {

    }

  }

  .jw-button {
    margin-left: 0.75rem;
  }
}
