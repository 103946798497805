.jw-details-with-icon {
  max-width: 520px;
  text-align: center;
  margin: 0 auto;

  .jw-details-with-icon__icon {
    font-size: 44px;
    color: map-get($colors, primary);
    margin-bottom: map-get($spacing, lg);
  }
}
