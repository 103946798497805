.trip-match-list {

  .trip-field {
    width: 20%;
    text-align: center;

    &.trip-field--status {
      text-transform: capitalize;
    }
  }

  .view-button {
    color: map-get($colors, dark);
    font-size: 14px;
    text-decoration: none;
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }
  }
}

.trip-match-list__legend {
  background: transparent;
  border: none;

  .trip-field {
    font-weight: bold;
    text-align: center;
  }
}

.trip-match-list__item {
  border-radius: 200px !important;
  border: none;
  margin: map-get($spacing, sm) 0;
  padding: map-get($spacing, md) map-get($spacing, xs);
}

.trip-match-list__item__contents, .trip-match-list__legend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
