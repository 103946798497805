@import "../../scss/variables";
@import "../../scss/typography-mixins";

.jw-info-container {
  display: flex;
  justify-content: center;
  margin-bottom: map-get($spacing, md);

  &:last-of-type {
    margin-bottom: map-get($spacing, defaultComponentMargin);
  }
}

.jw-info {
  padding: 0 map-get($spacing, md) 0 map-get($spacing, md);
  text-align: center;
  @include typography(sm-paragraph);

  .jw-list-pill__item & {
    padding: 0;
    text-align: inherit;
  }

  .jw-info__label {
    font-weight: bold;
  }

  &.jw-info--left {text-align: left};
  &.jw-info--right {text-align: right};
  &.jw-info--center {text-align: center};
}
