$inline-loader-container-height: 100px;
$inline-loader-spinner-size: 80px;

.inline-loader {
  width: 100%;
  height: 0;
  opacity: 0;
  text-align: center;
  transition: opacity 160ms ease-in-out;


  .inline-loader__spinner {
    margin-top: $inline-loader-container-height - $inline-loader-spinner-size;
    width: $inline-loader-spinner-size;
    height: $inline-loader-spinner-size;
  }

  &.inline-loader--loading {
    opacity: 1;
    height: $inline-loader-container-height;
  }
}
