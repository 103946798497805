@import "variables";

@mixin typography($type: default) {

  $typography-info: map-get($typography, $type);

  font-family: 'Montserrat', sans-serif;
  font-size: map-get($typography-info, size);
  font-weight: map-get($typography-info, weight);
  font-style: map-get($typography-info, font-style);
}


@mixin typography-primary-header($heading-level: h1) {
  $typography-info: map-get($typography, $heading-level);

  font-family: 'Montserrat', sans-serif;;
  font-size: map-get($typography-info, size);
  font-weight: map-get($typography-info, weight);
  margin-bottom: 1em;
}

@mixin typography-emphasized-header($heading-level: h1) {
  $typography-info: map-get($typography, #{$heading-level}-emphasized);

  font-family: 'Montserrat', sans-serif;;
  font-size: map-get($typography-info, size);
  font-weight: map-get($typography-info, weight);
  margin-bottom: 0.75em;
  letter-spacing: 0;
}
