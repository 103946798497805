@import "variables";
@import "typography-mixins";
@import "resets";
@import "override-bootstrap";
@import "~bootstrap/scss/bootstrap";

// Utilities
@import "./utilities";

// Component Specific Styling
@import "../ui-components/ui-components";

// App Specific Styling
@import "../app/trip-matches/trip-match-list.scss";
@import "../app/trip-matches/trip-match/trip-match-modal";
@import "../app/trip-match-details/details/detail-with-icon";
@import "../app/trip-request/trip-request-modal/trip-request-modal";
@import "../app/account/edit-account-info/editableAccountInfo";

body {
  @include typography(default);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

::placeholder, :-ms-input-placeholder, ::-webkit-input-placeholder {
  color: map-get($colors, placeholder);
}

$header-elements: h1, h2, h3, h4, h5, h6;
@each $header-element in $header-elements {

  /* apply header styling to header elements */
  #{$header-element} {
    @include typography-primary-header($header-element);
  }

  /* apply header styling to header classes ex: .h1 */
  .#{$header-element} {
    @include typography-primary-header($header-element);
  }

  /* apply header styling to emphasized header classes ex: .h1-emphasized */
  .#{$header-element}-emphasized {
    @include typography-emphasized-header($header-element);
  }


}
