.jw-list {
  padding-bottom: map-get($spacing, lg);

  .jw-list__item {
    border-top: solid 1px $gray-300;
    padding: map-get($spacing, lg) 0;
    &:last-child {
      border-bottom: solid 1px $gray-300;
    }
  }
}
