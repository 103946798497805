@import "../../../scss/variables";

.jw-modal {

  form {
    margin-bottom: 0;
  }

  .jw-modal__header {
    position: relative;
    border-bottom: 1px solid map-get($colors, neutral-light);

    .modal-title {
      margin: 0 map-get($spacing, xl);
      width: 100%;
      text-align: center;
    }
  }

  .btn-close {
    position: absolute;
    right: map-get($spacing, lg);
  }

  .flight-summary-graphic {
    width: 135px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: map-get($spacing, sm);
  }

  &.jw-modal--prevent-hide {
    .jw-modal__header {
      .btn-close {
        display: none;
      }
    }
  }

  .modal-content {
    border-radius: 20px;
    border: none;

    hr {
      max-width: 160px;
      margin-left: auto;
      margin-right: auto;
      height: 2px;
    }
  }

  .modal-footer {
    border-top: none;
    padding-bottom: map-get($spacing, xl);
  }
}
