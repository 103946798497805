@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

$typography: (
  default: (
    size: 1rem,
    weight: normal
  ),
  sm-paragraph: (
    size: 0.875rem,
    weight: normal,
  ),
  xs-paragraph: (
    size: 0.75rem,
    weight: normal,
  ),
  button: (
    size: 1rem,
    weight: bold,
  ),
  button-sm: (
    size: 0.875rem,
    weight: bold,
  ),
  button-back: (
    size: 1rem,
    weight: normal,
    font-style: italic,
  ),
  nav-link: (
    size: 0.875rem,
    weight: 500,
  ),
  badge: (
    size: 0.75rem,
    weight: bold,
  ),
  h1: (
    size: 2.25rem,
    weight: 500,
  ),
  h2: (
    size: 2rem,
    weight: 500,
  ),
  h3: (
    size: 1.625rem,
    weight: 500,
  ),
  h4: (
    size: 1.25rem,
    weight: 500,
  ),
  h5: (
    size: 0.875rem,
    weight: 500,
  ),
  h6: (
    size: 0.875rem,
    weight: 500,
  ),
  h1-emphasized: (
    size: 2.625rem,
    weight: bold,
  ),
  h2-emphasized: (
    size: 2.25rem,
    weight: bold,
  ),
  h3-emphasized: (
    size: 1.625rem,
    weight: bold,
  ),
  h4-emphasized: (
    size: 1.25rem,
    weight: bold,
  ),
  h5-emphasized: (
    size: 0.875rem,
    weight: bold,
  ),
  h6-emphasized: (
    size: 0.875rem,
    weight: bold,
  ),
);

$spacing: (
  xxs: 4px,
  xs: 4px,
  sm: 8px,
  md: 12px,
  lg: 20px,
  xl: 40px,
  xxl: 60px,
  xxxl: 80px,
  defaultComponentMargin: 20px, // used for the bottom of components and dom elements like tables, cards...
  lgComponentMargin: 100px, // below large things like cards and containers...
);

$colors: (
  //status colors
  primary: #1D71B8,
  white: #fff,
  neutral-light: #f0f3f8,
  neutral-gray: transparentize(#707070, 0.82),
  neutral-blue: #D0DCF0,
  red: #dc3545,
  neutral-red: lighten(#dc3545, 38%),
  dark: #151515,
  purple-light: #D2C7FE,
  green-light: #C7FCC7,
  blue-light: #C7ECFF,
  yellow-light: #FFE7B2,
  typography: #151515,
  typography-neutral: #717B88,
  placeholder: transparentize(#151515, 0.43),
);

$shadows: (
  primary: 0px 0px 96px transparentize(#032D50, 0.9),
);

$badge-colors: (
  purple: map-get($colors, purple-light),
  green: map-get($colors, green-light),
  blue: map-get($colors, blue-light),
  yellow: map-get($colors, yellow-light),
);

/* Override boostrap colors */
$theme-colors: (
  primary: map-get($colors, primary),
  dark: map-get($colors, dark),
  secondary: map-get($colors, purple-light),
  success: #28a745,
  warning: #ffc107,
  danger: map-get($colors, red),
  info: map-get($colors, blue-light),
  light: map-get($colors, neutral-light),
);

$nav-height: 80px;

$transition: (
  deafault: 380ms ease-in-out,
);
