@import "../../scss/variables";
@import "../../scss/utilities";

.jw-card-container {
  background: map-get($colors, white);
  border-radius: 30px;
  box-shadow: map-get($shadows, primary);
  padding: 0.5px;
  margin-bottom: map-get($spacing, lgComponentMargin);

  &.jw-card-container--gray {
    background: map-get($colors, neutral-light);
  }

  .jw-card-container__contents {
    margin: 40px;
  }

  &.jw-card-container--sm-padding {
    margin-bottom: map-get($spacing, defaultComponentMargin);
    .jw-card-container__contents {
      margin: map-get($spacing, md);
    }
  }

  &.jw-card-container--center-h {
    @include all-headers {
      text-align: center;
    }
  }
}
