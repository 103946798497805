@import "../../scss/variables";
@import "../../scss/typography-mixins";

$button-height: (
  default: 46px,
  small: 32px,
);

.jw-button {
  @include typography(button);
  display: flex;
  align-items: center;

  height: map-get($button-height, default);
  border-radius: map-get($button-height, default) / 2;
  border-width: 2.5px;
  padding-left: 20px;
  padding-right: 20px;

  &.btn-link {
    color: map-get($colors, dark);
  }

  &.btn-sm {
    height: map-get($button-height, small);
    border-radius: map-get($button-height, small) / 2;
    @include typography(button-sm);
  }

  .jw-button__arrow {
    margin-left: 6px;
  }

  &.jw-button--back {
    padding: 0 map-get($spacing, sm) 0 14px;
    height: auto;
    background: none;
    color: map-get($colors, typography);
    @include typography(button-back);
    border: none;
    position: relative;

    .jw-button__arrow {
      position: absolute;
      left: -8px;
      transform: rotateY(180deg);
      height: 14px;
      color: map-get($colors, primary);
    }
  }
}
