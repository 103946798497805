.jw-map-container {
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
}

.jw-map-container__map {
  border-radius: 40px;
  height: 48.6vh;
  width: 100%;
  overflow: visible;
}
