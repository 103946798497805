$tab-min-height: 60px;
$tab-active-min-height: 70px;

.jw-tabs {
  border-bottom: none;
  .nav-item {
    display: flex;
    align-items: end;

    .nav-link {
      position: relative;
      background: map-get($colors, neutral-blue);
      padding: 0 map-get($spacing, xl);
      border-radius: 20px 20px 0px 0px;
      min-height: $tab-min-height;
      width: 180px;
      transition: background-color 380ms;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(0deg, rgba(0,0,0,0.08), rgba(0,0,0,0));
        height: 24px;
        width: 100%;
      }

      &.active {
        min-height: $tab-active-min-height;
        border: none;
        background: map-get($colors, neutral-light);

        &:after {
          display: none;
        }
      }

      color: map-get($colors, typography) !important;
      @include typography(button);
    }

    &:not(:first-child) {
      .nav-link {
        margin-left: map-get($spacing, xxs);
      }

    }
  }
}

.tab-content {
  padding: map-get($spacing, lg);
  background: map-get($colors, neutral-light);
  border-radius: 0 20px 20px 20px;
  border: none;
}
