$gutter-size-x: map-get($spacing, sm);

.jw-condensed-grid {
  --bs-gutter-x: #{$gutter-size-x};
  &#{&} { // double specificity of css
    padding-left: 0;
    padding-right: 0;
  }
}

.jw-condensed-grid__row {
  --bs-gutter-x: #{$gutter-size-x};
  flex-wrap: nowrap;
  align-items: flex-end;
}

.jw-condensed-grid__col {
  --bs-gutter-x: #{$gutter-size-x};
}
