@import "../../scss/typography-mixins";
@import "../../scss/variables";

$datetime-icon-height: 14px;

.jw-info-datetime {
  @include typography(sm-paragraph);
  margin-bottom: map-get($spacing, defaultComponentMargin);


  .list-group-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .jw-info-datetime--icon {
    margin-right: map-get($spacing, sm);
    margin-bottom: 2.5px; // helps align icon with text
    height: $datetime-icon-height;
  }
}
